import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { colors, fonts } from "../../utils/theme";

const tutorialVideos = [
  {
    title: "Agreement Introduction",
    url: "https://youtube.com/shorts/6pZw5vqp19w",
  },
  {
    title: "I think I can handle the claim on my own",
    url: "https://youtube.com/shorts/f1i7tDZy1y8?feature=share",
  },
  {
    title: "My insurance company said I don't need a public adjuster",
    url: "https://youtube.com/shorts/EhZlvRV5mj0?feature=share",
  },
  {
    title: "I don't want to pay a percentage of my claim",
    url: "https://youtube.com/shorts/hTFEfQMpTx4?feature=share",
  },
  {
    title: "I already hired a contractor; they said they'd help with the claim",
    url: "https://youtube.com/shorts/6fBFVF5-ZWU?feature=share",
  },
  {
    title: "I'm worried this will delay my claim",
    url: "https://youtube.com/shorts/NTx-H5WBo-k?feature=share",
  },
  {
    title: "I have a lawyer—why do I need a public adjuster",
    url: "https://youtu.be/rz9eoOU_Eq8",
  },
  {
    title: "I don't want to switch adjusters—I already started my claim",
    url: "https://youtu.be/SG6grqh4OKo",
  },
  {
    title: "I heard public adjusters have a bad reputation",
    url: "https://youtube.com/shorts/DnZ2AEGZ0lw",
  },
  {
    title: "I don't think my damage is bad enough to need a public adjuster",
    url: "https://youtube.com/shorts/nc2-KrMONXI",
  },
  {
    title: "I don't want my insurance rates to go up",
    url: "https://youtube.com/shorts/cy2Rxr8cr_4?feature=share",
  },
  {
    title: "Can't I just wait and see how the claim goes",
    url: "https://youtube.com/shorts/Q5scu5slU7A",
  },
  {
    title: "Can I cancel if I change my mind",
    url: "https://youtube.com/shorts/wjBViRppfRw?feature=share",
  },
];

const TutorialCard = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  const handleCloseVideo = () => {
    setSelectedVideo(null);
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Understanding Your Agreement
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Watch these short videos to learn more about working with a public
          adjuster and the claims process.
        </Typography>

        {selectedVideo ? (
          <VideoPlayerContainer>
            <Typography variant="subtitle1" gutterBottom>
              {selectedVideo.title}
            </Typography>
            <Box sx={{ position: "relative" }}>
              <iframe
                width="100%"
                height="315"
                src={getEmbedUrl(selectedVideo.url)}
                title={selectedVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button variant="outlined" onClick={handleCloseVideo}>
                  Back to Video List
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  href={selectedVideo.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open in YouTube
                </Button>
              </Box>
            </Box>
          </VideoPlayerContainer>
        ) : (
          <VideoListContainer>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  <YouTubeIcon
                    sx={{
                      mr: 1,
                      verticalAlign: "middle",
                      color: colors.themeBlue,
                    }}
                  />
                  Getting Started
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VideoList>
                  {tutorialVideos.slice(0, 3).map((video, index) => (
                    <VideoItem key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => handleVideoSelect(video)}
                      >
                        <PlayCircleOutlineIcon
                          sx={{ color: colors.themeBlue, mr: 1 }}
                        />
                        <Typography variant="body2">{video.title}</Typography>
                      </Box>
                      <IconButton
                        size="small"
                        href={video.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: colors.themeBlue }}
                        title="Open in YouTube"
                      >
                        <YouTubeIcon fontSize="small" />
                      </IconButton>
                    </VideoItem>
                  ))}
                </VideoList>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  <YouTubeIcon
                    sx={{
                      mr: 1,
                      verticalAlign: "middle",
                      color: colors.themeBlue,
                    }}
                  />
                  Common Concerns
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VideoList>
                  {tutorialVideos.slice(3, 7).map((video, index) => (
                    <VideoItem key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => handleVideoSelect(video)}
                      >
                        <PlayCircleOutlineIcon
                          sx={{ color: colors.themeBlue, mr: 1 }}
                        />
                        <Typography variant="body2">{video.title}</Typography>
                      </Box>
                      <IconButton
                        size="small"
                        href={video.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: colors.themeBlue }}
                        title="Open in YouTube"
                      >
                        <YouTubeIcon fontSize="small" />
                      </IconButton>
                    </VideoItem>
                  ))}
                </VideoList>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  <YouTubeIcon
                    sx={{
                      mr: 1,
                      verticalAlign: "middle",
                      color: colors.themeBlue,
                    }}
                  />
                  Frequently Asked Questions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VideoList>
                  {tutorialVideos.slice(7).map((video, index) => (
                    <VideoItem key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => handleVideoSelect(video)}
                      >
                        <PlayCircleOutlineIcon
                          sx={{ color: colors.themeBlue, mr: 1 }}
                        />
                        <Typography variant="body2">{video.title}</Typography>
                      </Box>
                      <IconButton
                        size="small"
                        href={video.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: colors.themeBlue }}
                        title="Open in YouTube"
                      >
                        <YouTubeIcon fontSize="small" />
                      </IconButton>
                    </VideoItem>
                  ))}
                </VideoList>
              </AccordionDetails>
            </Accordion>
          </VideoListContainer>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default TutorialCard;

const StyledCard = styled(Card)`
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const VideoListContainer = styled(Box)`
  margin-top: 16px;
`;

const VideoList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const VideoItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const VideoPlayerContainer = styled(Box)`
  margin-top: 16px;
`;

const getEmbedUrl = (url) => {
  let videoId = "";

  if (url.includes("youtube.com/shorts/")) {
    const shortsMatch = url.match(/youtube\.com\/shorts\/([^/?&]+)/);
    if (shortsMatch && shortsMatch[1]) {
      videoId = shortsMatch[1];
    }
  } else if (url.includes("youtube.com/watch")) {
    const urlParams = new URL(url).searchParams;
    videoId = urlParams.get("v");
  } else if (url.includes("youtu.be/")) {
    const youtubeMatch = url.match(/youtu\.be\/([^/?&]+)/);
    if (youtubeMatch && youtubeMatch[1]) {
      videoId = youtubeMatch[1];
    }
  }

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  return url;
};
