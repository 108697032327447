import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { colors, fonts } from "../../utils/theme";
import TutorialCard from "../tutorial/TutorialCard";

const TutorialModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <DialogHeader>
        <DialogTitle
          sx={{
            fontFamily: fonts.semibold,
            fontSize: "1.5rem",
            color: colors.black,
            padding: 0,
          }}
        >
          Contract Signing Tutorial
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            color: colors.black,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogHeader>
      <DialogContent sx={{ p: 3 }}>
        <TutorialCard />
      </DialogContent>
    </Dialog>
  );
};

export default TutorialModal;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.lightGrey};
`;
