import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PrimaryButton from "../buttons/primary-button";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { colors, fonts } from "../../utils/theme";
import { useDeleteTemplate} from "../../hooks/data-hook";
import { useQueryClient } from "react-query";
import { useToast } from "../../context/toast.context";

const DeleteTemplateModal = ({ open, handleClose, id, inviteId, type, accessToken }) => {
  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: DeleteTemplate, isLoading: isDeleting } = useDeleteTemplate();

  const deleteTemplate = (id) => () => {
    console.log(id);
    DeleteTemplate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["templates"]);
        showSuccessToast("Template deleted!");
        handleClose();
      },
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "400px",
          borderRadius: { xs: 2, sm: 4 },
        },
      }}
    >
      <DialogContent>
        <SuccessContent>
          <div className="icon-circle"> 
            <DeleteIcon sx={{ color: "#FD6B6B", fontSize: 56 }} />
          </div>
          <h4>Delete template?</h4>
          <p> 
              Are you sure you want to delete this template?
          </p>
          <div className="btn-wrap">
            <div className="btn-container">
              <PrimaryButton
                onClick={handleClose}
                sx={{
                  bgcolor: "transparent",
                  boxShadow: "none",
                  color: colors.foreBlack,
                  border: "1px solid",
                  borderColor: colors.foreBlack,
                  "&:hover": {
                    bgcolor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                Cancel
              </PrimaryButton>
            </div>
            <div className="btn-container">
              <PrimaryButton
                onClick={deleteTemplate(id)}
                sx={{
                  bgcolor: "#FD6B6B",
                  boxShadow: "none",

                  "&:hover": {
                    bgcolor: "#FD6B6B",
                    boxShadow: "none",
                  },
                }}
                isLoading={isDeleting}
              >
                Delete
              </PrimaryButton>
            </div>
          </div>
        </SuccessContent>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteTemplateModal;

const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-circle {
    width: 96px;
    height: 96px;
    background-color: #ffe3e3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    font-family: ${fonts.semibold};
    font-size: 28px;
    color: #fd6b6b;
    margin-block: 24px;
  }

  p {
    font-family: ${fonts.regular};
    color: ${colors.foreBlack};
    font-size: 16px;
    text-align: center;
  }

  .btn-wrap {
    margin-top: 24px;
    width: 100%;
    display: flex;
    margin-left: -24px;

    .btn-container {
      width: 100%;
      margin-left: 24px;
    }
  }
`;
